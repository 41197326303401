import React from 'react'
import Img from "gatsby-image"
import { withTranslation } from "react-i18next"
import './team.scss'

class Team extends React.Component {

    
    render() {

        const AOS_DELAY = 200

        const { team, t } = this.props

        return (
            <div class="team-area pb-65">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title text-center">
                                <span>The hotel <strong>unforgettable</strong></span>
                                <h3>{t('team')}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12 ">
                            <div class="single-team-area mb-30 owner">
                                <div class="team-img">
                                    <Img sizes={team.frontmatter.imageowner.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('homoky_peter')}</h4>
                                    <span class="position">{t('owner')}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row d-flex justify-content-center">

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="single-team-area mb-30">
                                <div class="team-img">
                                   <Img sizes={team.frontmatter.imagehotelmanager.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('czako_nora')}</h4>
                                    <span class="position">{t('hotel_manager')}</span>
                                    <ul class="social-network">
                                        <li><a class="email" href="mailto:director@hoteltiliana.hu" target="_blank"><i class="fa fa-envelope"></i></a></li>
                                        <li><a class="phone" href="tel:+36 1 391 0027"><i class="fa fa-phone"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="single-team-area mb-30">
                                <div class="team-img">
                                   <Img sizes={team.frontmatter.imagesales.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('sztancsek_peter')}</h4>
                                    <span class="position">{t('sales')}</span>
                                    <ul class="social-network">
                                        <li><a class="email" href="mailto:sales@hoteltiliana.hu" target="_blank"><i class="fa fa-envelope"></i></a></li>
                                        <li><a class="phone" href="tel:+36-70-666-6663"><i class="fa fa-phone"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="single-team-area mb-30">
                                <div class="team-img">
                                   <Img sizes={team.frontmatter.imagefinancemanager.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('toth-kriszta')}</h4>
                                    <span class="position">{t('financial-manager')}</span>
                                    <ul class="social-network">
                                        <li><a class="email" href="mailto:kriszta.toth@hct.hu" target="_blank"><i class="fa fa-envelope"></i></a></li>
                                        <li><a class="phone" href="tel:+36 70 410 9999"><i class="fa fa-phone"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="single-team-area mb-30">
                                <div class="team-img">
                                <Img sizes={team.frontmatter.imagesalesmanager.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('kovacs-kitti')}</h4>
                                    <span class="position">{t('event_sales')}</span>
                                    <ul class="social-network">
                                        <li><a class="email" href="mailto:event@homokyhotels.hu" target="_blank"><i class="fa fa-envelope"></i></a></li>
                                        <li><a class="phone" href="tel:+36 70 666 6668"><i class="fa fa-phone"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="single-team-area mb-30">
                                <div class="team-img">
                                <Img sizes={team.frontmatter.imagerestaurantmanager.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('kallay-laszlo')}</h4>
                                    <span class="position">{t('restaurant_manager')}</span>
                                    <ul class="social-network">
                                        <li><a class="email" href="mailto:etalon@homokyhotels.hu" target="_blank"><i class="fa fa-envelope"></i></a></li>
                                        <li><a class="phone" href="tel:+36 70 310 9999"><i class="fa fa-phone"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="single-team-area mb-30">
                                <div class="team-img">
                                <Img sizes={team.frontmatter.imagehousekeepingmanager.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('repasi-klaudia')}</h4>
                                    <span class="position">{t('housekeeping_manager')}</span>
                                    <ul class="social-network">
                                        <li><a class="phone" href="tel:+36 1 391 00 27"><i class="fa fa-phone"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div class="single-team-area mb-30">
                                <div class="team-img">
                                <Img sizes={team.frontmatter.imagechef.childImageSharp.fluid} />
                                </div>
                                <div class="team-info">
                                    <h4 class="name">{t('hornyak-otto')}</h4>
                                    <span class="position">{t('chef')}</span>
                                    <ul class="social-network">
                                        <li><a class="email" href="mailto:konyha@hoteltiliana.hu" target="_blank"><i class="fa fa-envelope"></i></a></li>
                                        <li><a class="phone" href="tel:+36 70 340 1111"><i class="fa fa-phone"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        
        )
    }
}
export default withTranslation()(Team)